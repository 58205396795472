import axios from 'axios'
import auth from './auth'
import Cookies from 'js-cookie'

const token = Cookies.get('token')

export const docApi = axios.create({
  baseURL: process.env.REACT_APP_DOC_URL,
  headers: token ? { Authorization: `Bearer ${token}` } : {},
  // timeout: 1000,
})

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: token ? { Authorization: `Bearer ${token}` } : {},
  // timeout: 1000,
})

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      auth.emit('clear-auth')
    }

    return Promise.reject(error)
  }
)

export const setAxiosDefaultHeader = (key, value) => {
  if (!value) {
    delete api.defaults.headers.common[key]
    delete docApi.defaults.headers.common[key]
  } else {
    api.defaults.headers.common[key] = value
    docApi.defaults.headers.common[key] = value
  }
}

export default api
