import React from 'react'
import { Location } from '@reach/router'
import styled from '@emotion/styled'
import { Box, Row, Button, NavLink, Menu, MenuButton, MenuList, MenuItem } from './ui'
import auth from './auth'

const Navbar = styled(Box)`
  position: relative;
  width: 100%;
  height: 50px;
  padding: 0 1rem;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0),
    0 1px 1px rgba(16, 22, 26, 0.2);
  z-index: 10;
  font-size: 0.875rem;
`

const NavRow = styled(Row)`
  height: 50px;
`

const NavbarDivider = styled(Box)`
  margin: 0 10px;
  border-left: 1px solid rgba(16, 22, 26, 0.15);
  height: 20px;
`

const Header = ({ authUser }) => (
  <Location>
    {({ location: { pathname } }) => (
      <Navbar>
        <NavRow alignItems="center">
          <Box display={['none', 'none', 'block']} px={3}>
            N E X U S
          </Box>

          <Box display={['block', 'block', 'none']} px={3} fontWeight={600}></Box>

          <NavbarDivider />

          <Row display={['none', 'none', 'flex']} flex={1} px={3}>
            <Box px={1}>
              <NavLink to="/plans" active={pathname.indexOf('/plans') === 0}>
                Plans
              </NavLink>
            </Box>

            <Box px={1}>
              <NavLink
                to="/capability-templates"
                active={pathname.indexOf('/capability-templates') === 0}
              >
                Capability Templates
              </NavLink>
            </Box>
          </Row>

          <Row flex={1} />

          <Menu>
            <MenuButton
              as={Button}
              size="sm"
              variant="ghost"
              fontWeight="normal"
              rightIcon="chevron-down"
            >
              {authUser && authUser.name}
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => auth.emit('clear-auth')}>Logout</MenuItem>
            </MenuList>
          </Menu>
        </NavRow>
      </Navbar>
    )}
  </Location>
)
export default Header
