import { get, groupBy } from 'lodash'
import { toast } from 'react-toastify'

export const addEntityTypes = ({ entities, capability_types }) => {
  return entities.map(entity => {
    let byType = groupBy(entity.capabilities, e => e.type_id)

    return {
      ...entity,
      types: Object.keys(byType).map(type_id => {
        let type = capability_types.find(t => t.id === type_id)
        let byCategory = groupBy(byType[type_id], e => e.type_category)
        let categoryKeys = Object.keys(byCategory)

        return {
          ...type,
          entity,
          grouped: categoryKeys.length > 1 ? byCategory : null,
          simple: categoryKeys.length === 1 ? byCategory[categoryKeys[0]] : null,
        }
      }),
    }
  })
}

export const makeCapabilityOrder = ({ entity }) => {
  return sortByName(entity.types).reduce((acc, capability_type) => {
    let simple = capability_type.simple
      ? sortByName(capability_type.simple).map(capability => capability.id)
      : []

    let [...grouped] = capability_type.grouped
      ? sortCategories(Object.keys(capability_type.grouped)).reduce((acc, category) => {
          return [
            ...acc,
            ...sortByName(capability_type.grouped[category]).map(capability => capability.id),
          ]
        }, [])
      : []

    return [...acc, ...simple, ...grouped]
  }, [])
}

export const apiErrorToast = err => {
  let message = get(err, 'response.data.error') || 'There was a problem'

  toast.error(`Whoops! ${message}`)
}

export const SATISFIED = {
  satisfied: 'satisfied',
  not_satisfied: 'not_satisfied',
  satisfied_other: 'satisfied_other',
  other: 'other',
}

export const sortCategories = list => [...list].sort((a, b) => (a < b ? -1 : b < a ? 1 : 0))

export const sortByName = list =>
  [...list].sort((a, b) => (a.name < b.name ? -1 : b.name < a.name ? 1 : 0))
