import React from 'react'
import { ButtonLink, Box, Row, Column, Divider, Heading, Spacer, Stack } from '../../ui'
import { addEntityTypes, makeCapabilityOrder, sortCategories, sortByName } from '../../utils'
import CapabilityRow from './capability-row'

export default class Survey extends React.Component {
  render() {
    let { plan_id, entity_id, plans, capability_types } = this.props

    if (plans.length === 0) return null

    let plan = plans.find((p) => p.id === plan_id)

    if (!plan) {
      return (
        <Row justifyContent="center" mt={5}>
          <Box fontSize="lg">Unknown plan</Box>
        </Row>
      )
    }

    let entity = addEntityTypes({ entities: plan.entities, capability_types }).find(
      (e) => e.id === entity_id
    )
    let idOrder = makeCapabilityOrder({ entity })

    return (
      <Box fontSize="sm" mb={6}>
        <Row>
          <Row flex="1">
            <Heading size="lg" fontWeight={400}>
              {entity.name}
            </Heading>
          </Row>

          <Row>
            <ButtonLink to={`/plans/${plan.id}`} size="sm" variantColor="blue">
              Back to Plan
            </ButtonLink>
          </Row>
        </Row>

        <Spacer spacing={8} />

        <SurveyCapabilityList
          plan={plan}
          entity={entity}
          idOrder={idOrder}
          saveCapability={this.props.updatePlanCapability}
          dataById={idOrder.reduce(
            (acc, id) => ({
              ...acc,
              [id]: entity.capabilities.find(({ id: cid }) => cid === id),
            }),
            {}
          )}
        />
      </Box>
    )
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////

const nextActiveId = (idOrder, id) => idOrder[idOrder.indexOf(id) + 1] || id

class SurveyCapabilityList extends React.Component {
  state = {
    caprefs: {},
    activeId: this.props.idOrder[0],
    touchedId: null,
    promptSaveId: null,
  }

  setActiveId = (activeId) => {
    if (this.state.touchedId && this.state.touchedId !== activeId) {
      this.setState({ promptSaveId: this.state.touchedId })
      return
    }

    this.setState({ activeId })
  }

  updateCapRefs = (newref) => {
    this.setState((state) => ({ ...state, caprefs: { ...state.caprefs, ...newref } }))
  }

  saveCapability = ({ id, data }) => {
    return this.props.saveCapability({ id, data }).then((capability) => {
      this.setState((state) => ({
        ...state,
        touchedId: null,
        promptSaveId: null,
        activeId: nextActiveId(this.props.idOrder, id),
      }))
    })
  }

  componentDidUpdate(prevProps, prevstate) {
    if (prevstate.activeId !== this.state.activeId) {
      let ref = this.state.caprefs[this.state.activeId]
      let top = ref.offsetTop - window.innerHeight / 2 + ref.offsetHeight

      window.scrollTo({ top, behavior: 'smooth' })
    }
  }

  render() {
    let { entity, plan, dataById } = this.props

    // console.log('SurveyCapabilityList - render', { entity, plan })

    return (
      <Stack spacing={8}>
        {sortByName(entity.types).map((capability_type) => (
          <Column key={`${entity.id}-${capability_type.id}`}>
            <Heading size="md" fontWeight={400}>
              {capability_type.name}
            </Heading>

            <Divider />

            <Spacer spacing={2} />

            {capability_type.simple && (
              <Stack spacing={4}>
                {sortByName(capability_type.simple).map((capability, index) => (
                  <CapabilityRow
                    key={capability.id}
                    index={index}
                    isActive={this.state.activeId === capability.id}
                    isTouched={this.state.touchedId === capability.id}
                    isPromptedSave={this.state.promptSaveId === capability.id}
                    capability={capability}
                    entities={plan.entities}
                    entity={entity}
                    values={dataById[capability.id]}
                    updateCapRefs={this.updateCapRefs}
                    setActiveId={this.setActiveId}
                    saveCapability={this.saveCapability}
                    setParentState={this.setState.bind(this)}
                  />
                ))}
              </Stack>
            )}

            {capability_type.grouped && (
              <Stack spacing={4}>
                {sortCategories(Object.keys(capability_type.grouped)).map((category) => (
                  <Box key={category}>
                    <Heading fontSize="md" fontWeight={500}>
                      {category}
                    </Heading>

                    <Spacer spacing={4} />

                    <Stack spacing={4}>
                      {sortByName(capability_type.grouped[category]).map((capability, index) => (
                        <CapabilityRow
                          key={capability.id}
                          index={index}
                          isActive={this.state.activeId === capability.id}
                          isTouched={this.state.touchedId === capability.id}
                          isPromptedSave={this.state.promptSaveId === capability.id}
                          capability={capability}
                          entities={plan.entities}
                          entity={entity}
                          values={dataById[capability.id]}
                          updateCapRefs={this.updateCapRefs}
                          setActiveId={this.setActiveId}
                          saveCapability={this.saveCapability}
                          setParentState={this.setState.bind(this)}
                        />
                      ))}
                    </Stack>
                  </Box>
                ))}
              </Stack>
            )}
          </Column>
        ))}

        <Spacer spacing={6} />

        <Row justifyContent="flex-end">
          <Box>
            <ButtonLink to={`/plans/${plan.id}`} scale="large" color="primary" outline="outline">
              Complete Survey
            </ButtonLink>
          </Box>
        </Row>
      </Stack>
    )
  }
}
