import React, { Component } from 'react'
import { Button, Column, Row, Box, Dialog } from '../ui'

export class DeleteDialog extends Component {
  dialogRef = React.createRef()

  render() {
    return (
      <Dialog isOpen={true} initialFocusRef={this.dialogRef} onDismiss={this.props.cancelHandler}>
        <Dialog.Title>Delete Capability Template</Dialog.Title>

        <Dialog.Body>
          <Column>
            <Box>Are you sure you want to delete this capability template?</Box>
            <ul>
              <li>{this.props.name}</li>
            </ul>
          </Column>
        </Dialog.Body>

        <Dialog.Footer>
          <Button outline mr={2} ref={this.dialogRef} onClick={this.props.cancelHandler}>
            Cancel
          </Button>

          <Button color="primary" onClick={this.props.acceptHandler}>
            Delete
          </Button>
        </Dialog.Footer>
      </Dialog>
    )
  }
}

export class AddCategoryDialog extends Component {
  state = {
    name: '',
  }

  dialogRef = React.createRef()

  handleChange = (e) => {
    this.setState({ name: e.currentTarget.value })
  }

  render() {
    let { type } = this.props

    return (
      <Dialog isOpen={true} initialFocusRef={this.dialogRef} onDismiss={this.props.cancelHandler}>
        <Dialog.Title>Add Capability Type Category</Dialog.Title>

        <Dialog.Body>
          <Column>
            <Box mb={3}>This will add a new category to the "{type.name}" capability type.</Box>
            {/* <Box mb={2}>Category Name</Box> */}
            <Row justifyContent="center">
              <input
                type="text"
                value={this.state.name}
                onChange={this.handleChange}
                placeholder="Category Name"
                style={{ width: 250 }}
              />
            </Row>
          </Column>
        </Dialog.Body>

        <Dialog.Footer>
          <Button outline mr={2} ref={this.dialogRef} onClick={this.props.cancelHandler}>
            Cancel
          </Button>

          <Button color="primary" onClick={() => this.props.acceptHandler(this.state.name)}>
            Add
          </Button>
        </Dialog.Footer>
      </Dialog>
    )
  }
}
