import React, { Component } from 'react'
import { Button, Row, Box, Divider, Heading, Spacer, Stack, Text } from '../ui'
import { sortCategories, sortByName } from '../utils'
import { AddCategoryDialog, DeleteDialog } from './dialogs'
import CapabilityTemplate from './capability-template'

class Capabilities extends Component {
  state = {
    deleteDialog: null,
    addCategoryDialog: null,
    editingId: null,
  }

  startAddCategory = (type) => this.setState({ addCategoryDialog: { type } })

  closeAddCategoryDialog = () => this.setState({ addCategoryDialog: null })

  doAddCategory = (name) => {
    let { type } = this.state.addCategoryDialog
    let oldCategories = type.categories || []
    let categories = [...oldCategories, name]

    this.props.updateCapabilityType(type.id, { categories }).then(this.closeAddCategoryDialog)
  }

  startDelete = ({ id, name }) => this.setState({ deleteDialog: { id, name } })

  closeDeleteDialog = () => this.setState({ deleteDialog: null })

  doDelete = () =>
    this.props.deleteCapability(this.state.deleteDialog.id).then(this.closeDeleteDialog)

  render() {
    let { editingId } = this.state
    let { capability_types, capabilities, saveCapability } = this.props

    let types = groupCapabilities(capability_types, capabilities)

    const updateEditingId = (editingId) => this.setState({ editingId })

    return (
      <>
        <Text fontSize="sm">These are the templates for all capabilities.</Text>
        <Text fontSize="sm">
          Changes made here will only be used for future plans, and{' '}
          <strong>does not affect existing plans</strong>.
        </Text>

        <Spacer spacing={6} />

        <Stack spacing={12}>
          {sortByName(types).map((type) => {
            return (
              <Box key={type.id}>
                <Row height="2rem" justifyContent="space-between" alignItems="center" px={6}>
                  <Heading size="md">{type.name}</Heading>

                  <Box>
                    {!editingId && (
                      <Button
                        size="xs"
                        variantColor="blue"
                        onClick={() => this.startAddCategory(type)}
                      >
                        Add Category
                      </Button>
                    )}
                  </Box>
                </Row>

                <Spacer spacing={4} />

                <Box bg="white" p={6} borderRadius={3} boxShadow="md">
                  {type.grouped && (
                    <CapabilityGroup
                      editingId={editingId}
                      type={type}
                      saveCapability={saveCapability}
                      updateEditingId={updateEditingId}
                      startDelete={this.startDelete}
                      setState={this.setState.bind(this)}
                    />
                  )}

                  {type.simple && (
                    <CapabilityGroup
                      editingId={editingId}
                      type={type}
                      saveCapability={saveCapability}
                      updateEditingId={updateEditingId}
                      startDelete={this.startDelete}
                      setState={this.setState.bind(this)}
                    />
                  )}

                  {!type.grouped && !type.simple && (
                    <>
                      <Box ml={3}>
                        <em>No capabilities yet</em>
                      </Box>

                      <AddCapabilityForm
                        type={type}
                        saveCapability={saveCapability}
                        isEditing={editingId === 'simple'}
                        showForm={() => this.setState({ editingId: 'simple' })}
                        hideForm={() => this.setState({ editingId: null })}
                      />
                    </>
                  )}
                </Box>
              </Box>
            )
          })}
        </Stack>

        {!!this.state.deleteDialog && (
          <DeleteDialog
            acceptHandler={this.doDelete}
            cancelHandler={this.closeDeleteDialog}
            {...this.state.deleteDialog}
          />
        )}

        {!!this.state.addCategoryDialog && (
          <AddCategoryDialog
            acceptHandler={this.doAddCategory}
            cancelHandler={this.closeAddCategoryDialog}
            {...this.state.addCategoryDialog}
          />
        )}
      </>
    )
  }
}

export default Capabilities

///////////////////////////////////////////////////////////////////////////////////////////////////

const CapabilityGroup = ({
  editingId,
  type,
  saveCapability,
  updateEditingId,
  startDelete,
  setState,
}) => {
  let byCategory = type.grouped ? type.grouped : { [type.name]: type.simple }
  let categories = Object.keys(byCategory)

  return (
    <>
      {sortCategories(categories).map((category, index) => (
        <React.Fragment key={category}>
          <Stack spacing={1}>
            <Row minHeight="2rem" justifyContent="space-between" alignItems="center">
              <Heading size="md" color="blue.600" fontWeight={500}>
                {category}
              </Heading>

              <Box>
                {!editingId && (
                  <Button size="xs" onClick={() => setState({ editingId: `cat_${category}` })}>
                    Add Capability
                  </Button>
                )}
              </Box>
            </Row>

            <AddCapabilityForm
              type={type}
              category={category}
              saveCapability={saveCapability}
              isEditing={editingId === `cat_${category}`}
              hideForm={() => setState({ editingId: null })}
            />

            {sortByName(byCategory[category]).map((capability, index) => (
              <CapabilityTemplate
                key={capability.id}
                index={index}
                capability={capability}
                saveCapability={saveCapability}
                startDelete={startDelete}
                isEditing={editingId === capability.id}
                otherIsEditing={!!editingId && editingId !== capability.id}
                updateEditingId={updateEditingId}
              />
            ))}
          </Stack>

          {index < categories.length - 1 && <Divider my={6} />}
        </React.Fragment>
      ))}
    </>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

class AddCapabilityForm extends Component {
  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  render() {
    let { type, category, isEditing, hideForm } = this.props

    if (!isEditing) return null

    return (
      <Box>
        <CapabilityTemplate
          key={999}
          isEditing
          capability={{
            name: '',
            narratives: { satisfied: '{entity}', not_satisfied: '{entity}' },
          }}
          handleCancelSave={hideForm}
          saveCapability={({ data }) => {
            return this.props.saveCapability({
              data: {
                ...data,
                type_id: type.id,
                type_category: type.grouped ? category : undefined,
              },
            })
          }}
          afterSave={(capability) => {
            if (!this.mounted) return
            hideForm()
          }}
        />
      </Box>
    )
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////

const groupCapabilities = (capability_types, allCapabilities) => {
  return capability_types.map((type) => {
    let capabilities = allCapabilities.filter((c) => c.type_id === type.id)
    let categories = type.categories || null

    return {
      ...type,
      grouped: categories
        ? categories.reduce(
            (acc, category) => ({
              ...acc,
              [category]: capabilities.filter((c) => c.type_category === category),
            }),
            {}
          )
        : null,
      simple: categories || capabilities.length === 0 ? null : capabilities,
    }
  })
}
