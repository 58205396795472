import React from 'react'
import { PseudoBox } from '@chakra-ui/core'

const Row = React.forwardRef((props, ref) => <PseudoBox {...props} ref={ref} />)

Row.defaultProps = {
  display: 'flex',
}

Row.displayName = 'Row'

export default Row
