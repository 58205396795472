import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Box, ButtonGroup, Icon, IconButton, Spinner } from '@chakra-ui/core'
import Row from './row'
import { useTable as useReactTable, useSortBy, usePagination } from 'react-table'

const Table = styled.table(
  ({ theme }) => css`
    width: 100%;
    border-spacing: 0;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: ${theme.shadows.md};
    font-size: 0.875rem;

    thead tr th:first-of-type {
      border-top-left-radius: 3px;
    }

    thead tr th:last-of-type {
      border-top-right-radius: 3px;
    }

    tbody tr:last-of-type td:first-of-type {
      border-bottom-left-radius: 3px;
    }

    tbody tr:last-of-type td:last-of-type {
      border-bottom-right-radius: 3px;
    }

    thead tr th {
      /* font-size: 0.9em; */
      color: ${theme.colors.gray[500]};
      background-color: ${theme.colors.gray[100]};
      text-transform: uppercase;
      text-align: left;
    }

    th {
      min-height: 40px;
      padding: 0.5rem 1rem;
    }

    td {
      min-height: 40px;
      padding: 0.5rem 1rem;
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    td {
      border-bottom: 1px solid ${theme.colors.gray[100]};
    }

    td.loading-indicator {
      width: 100%;
      height: 4rem;
      text-align: center;
      background: #fff;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  `
)

///////////////////////////////////////////////////////////////////////////////

const Header = ({ headerGroups }) => (
  <thead>
    {headerGroups.map((headerGroup) => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
            {column.render('Header')}

            <span css={{ position: 'absolute', marginLeft: 8, marginTop: -2 }}>
              {column.isSorted ? (
                column.isSortedDesc ? (
                  <Icon name="chevron-down" size="1rem" />
                ) : (
                  <Icon name="chevron-up" size="1rem" />
                )
              ) : (
                ''
              )}
            </span>
          </th>
        ))}
      </tr>
    ))}
  </thead>
)

///////////////////////////////////////////////////////////////////////////////

const Body = ({ isLoading, rows, getTableBodyProps, prepareRow, onRowClick }) => {
  if (isLoading) {
    return (
      <tbody>
        <tr>
          <td colSpan={100} className="loading-indicator">
            <Spinner speed="0.65s" size="md" />
          </td>
        </tr>
      </tbody>
    )
  }

  if (rows.length === 0) {
    return (
      <tbody {...getTableBodyProps()}>
        <tr>
          <td colSpan={100}>
            <Row
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="2rem"
              color="gray.500"
              fontWeight={500}
            >
              No Data
            </Row>
          </td>
        </tr>
      </tbody>
    )
  }

  return (
    <tbody {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row)

        return (
          <tr
            {...row.getRowProps()}
            onClick={onRowClick ? () => onRowClick(row.original) : undefined}
            css={
              onRowClick
                ? {
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#F7FAFC',
                    },
                  }
                : undefined
            }
          >
            {row.cells.map((cell) => {
              return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
            })}
          </tr>
        )
      })}
    </tbody>
  )
}

///////////////////////////////////////////////////////////////////////////////

const Pagination = ({
  // mine
  totalCount,

  // react-table
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize,
}) => {
  return (
    <Row justifyContent="flex-end" alignItems="center" py={2} fontSize="sm">
      <Box px={2} color="gray.500" fontWeight={500}>
        {totalCount} Items
      </Box>

      {pageOptions.length > 1 && (
        <Box px={2} color="gray.500" fontWeight={500}>
          {`Page ${pageIndex + 1} of ${pageOptions.length}`}
        </Box>
      )}

      {pageOptions.length > 1 && (
        <ButtonGroup spacing={1} color="gray.500">
          <IconButton
            variant="outline"
            // variantColor="gray"
            size="sm"
            icon="arrow-left"
            aria-label="First"
            fontSize="0.8rem"
            isDisabled={!canPreviousPage}
            onClick={() => gotoPage(0)}
          />

          <IconButton
            variant="outline"
            // variantColor="gray"
            size="sm"
            icon="chevron-left"
            aria-label="Previous"
            fontSize="1.5rem"
            isDisabled={!canPreviousPage}
            onClick={() => previousPage()}
          />

          <IconButton
            variant="outline"
            // variantColor="gray"
            size="sm"
            icon="chevron-right"
            aria-label="Next"
            fontSize="1.5rem"
            isDisabled={!canNextPage}
            onClick={() => nextPage()}
          />

          <IconButton
            variant="outline"
            // variantColor="gray"
            size="sm"
            icon="arrow-right"
            aria-label="First"
            fontSize="0.8rem"
            isDisabled={!canNextPage}
            onClick={() => gotoPage(pageCount - 1)}
          />
        </ButtonGroup>
      )}

      {false && (
        <>
          <span>
            | Go to page:
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </>
      )}
    </Row>
  )
}

///////////////////////////////////////////////////////////////////////////////

Table.Header = Header
Table.Body = Body
Table.Pagination = Pagination

export default Table

///////////////////////////////////////////////////////////////////////////////

export const useTable = ({ data, columns, tableOptions }) => {
  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useReactTable(
    {
      columns,
      data,
      ...tableOptions,
    },
    useSortBy,
    usePagination
  )

  return {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows, // all rows
    page, // current page of rows
    pagination: {
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      pageIndex,
      pageSize,
    },
    // mine
    totalCount: rows.length,
  }
}
