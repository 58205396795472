import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

// https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_custom_radio
let InputRadioStyle = styled('label')(
  ({ checked }) => css`
    display: flex;
    align-items: center;
    height: 28px;
    position: relative;
    padding-left: 24px;
    user-select: none;
    cursor: pointer;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /* Create a custom radio button */
    span {
      position: absolute;
      top: 7px;
      left: 0;
      height: 16px;
      width: 16px;
      background-color: #eee;
      border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    &:hover span {
      ${checked ? '' : `background-color: #ccc;`};
    }

    /* When the radio button is checked, add a blue background */
    span {
      ${checked ? `background-color: #2196f3` : ''}; /* blue */
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    /* Show the indicator (dot/circle) when checked */
    /* Style the indicator (dot/circle) */
    span:after {
      content: '';
      display: ${checked ? 'block' : 'none'};
      position: absolute;
      top: 5px;
      left: 5px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: white;
    }
  `
)

const InputRadio = ({ label, onChange, checked = false }) => (
  <InputRadioStyle checked={checked}>
    {label}
    <input type="radio" name="radio" checked={checked} onChange={onChange} />
    <span />
  </InputRadioStyle>
)

export default InputRadio
