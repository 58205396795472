import React from 'react'
import RectSelect from 'react-select'
import { Creatable as ReactCreatableSelect } from 'react-select'

const Select = ({ options, value, onChange, isClearable = true, disabled = false }) => (
  <RectSelect
    options={options}
    isClearable={isClearable}
    isDisabled={disabled}
    value={value}
    theme={theme => ({
      ...theme,
      spacing: {
        ...theme.spacing,
        baseUnit: 2,
        controlHeight: 30,
        menuGutter: 2,
      },
    })}
    onChange={onChange}
  />
)

export const CreatableSelect = ({
  options,
  value,
  onChange,
  onCreateOption,
  isClearable = true,
  disabled = false,
}) => (
  <ReactCreatableSelect
    options={options}
    isClearable={isClearable}
    isDisabled={disabled}
    value={value}
    theme={theme => ({
      ...theme,
      spacing: {
        ...theme.spacing,
        baseUnit: 2,
        controlHeight: 30,
        menuGutter: 2,
      },
    })}
    onChange={onChange}
    onCreateOption={onCreateOption}
  />
)

export default Select
