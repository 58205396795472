import React, { Component } from 'react'
import styled from '@emotion/styled'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { Box } from '@chakra-ui/core'

const Overlay = styled(DialogOverlay)`
  background-color: rgba(90, 104, 114, 0.8);

  /* reset reach-ui defaults */
  /* https://github.com/reach/reach-ui/issues/58 */
  [data-reach-dialog-content] {
    padding: 0;
  }
`

const Content = styled(DialogContent)`
  max-width: 500px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
`

const Title = styled(Box)`
  height: 56px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: rgba(90, 104, 114, 1);
  background-color: rgba(240, 243, 246, 1);
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Body = styled(Box)`
  min-height: 112px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
`

const Footer = styled(Box)`
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(240, 243, 246, 1);
  padding: 0 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`

class Dialog extends Component {
  static Title = Title
  static Body = Body
  static Footer = Footer

  static defaultProps = {
    isOpen: true,
  }

  render() {
    let { isOpen, onDismiss, initialFocusRef } = this.props

    return (
      <Overlay isOpen={isOpen} onDismiss={onDismiss} initialFocusRef={initialFocusRef}>
        <Content>{this.props.children}</Content>
      </Overlay>
    )
  }
}

export default Dialog
