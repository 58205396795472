import React from 'react'
import { PseudoBox } from '@chakra-ui/core'

const Column = React.forwardRef((props, ref) => <PseudoBox {...props} ref={ref} />)

Column.defaultProps = {
  display: 'flex',
  flexDirection: 'column',
}

Column.displayName = 'Column'

export default Column
