import React from 'react'
import { navigate } from '@reach/router'
import { Box, Table, useTable } from '../ui'

const PlanTable = ({ data }) => {
  console.log('PlanTable - render', { data })

  let columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        accessor: 'name',
      },
      {
        Header: 'Entities',
        id: 'entities',
        accessor: 'entities',
        sortType: ({ original: a }, { original: b }) =>
          a.entities.length < b.entities.length ? -1 : 1,
        Cell: ({ row }) => <Box>{row.original.entities.length}</Box>,
      },
      {
        Header: 'Created',
        id: 'created_at',
        accessor: 'created_at',
        Cell: ({ row }) => {
          let formatted = new Date(row.original.created_at).toLocaleDateString()

          return (
            <time dateTime={formatted} title={formatted}>
              {formatted}
            </time>
          )
        },
      },
      {
        Header: 'Updated',
        id: 'updated_at',
        accessor: 'updated_at',
        Cell: ({ row }) => {
          if (!row.original.updated_at) return '-'

          let formatted = new Date(row.original.updated_at).toLocaleDateString()

          return (
            <time dateTime={formatted} title={formatted}>
              {formatted}
            </time>
          )
        },
      },
    ],
    []
  )

  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // rows,
    page,
    pagination,
    totalCount,
  } = useTable({
    data,

    tableOptions: {
      initialState: {
        sortBy: [{ id: 'name' }],
      },
    },

    columns,
  })

  return (
    <>
      <Table {...getTableProps()}>
        <Table.Header headerGroups={headerGroups} />
        <Table.Body
          rows={page}
          prepareRow={prepareRow}
          getTableBodyProps={getTableBodyProps}
          isLoading={data.fetching}
          onRowClick={(obj) => navigate(`/plans/${obj.id}`)}
        />
      </Table>

      {!data.fetching && <Table.Pagination totalCount={totalCount} {...pagination} />}
    </>
  )
}

export default PlanTable
