// import React from 'react'
// import { Global, css } from '@emotion/core'
import 'react-virtualized/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import '@reach/dialog/styles.css'

export default function GlobalStyles() {
  return null
  // return <Global styles={css``} />
}
