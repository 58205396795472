import React from 'react'
import { Link } from '@reach/router'
import { Button } from '@chakra-ui/core'

const ButtonLink = React.forwardRef(({ variant, ...props }, ref) => (
  <Button
    as={Link}
    ref={ref}
    variant={variant}
    css={{
      '&:hover': {
        textDecoration: variant !== 'link' ? 'none' : undefined,
      },
    }}
    {...props}
  />
))

export default ButtonLink
