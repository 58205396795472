import React, { Component } from 'react'
import { Input } from '@chakra-ui/core'
import api from '../api'
import { Button, Box, Column, Row, Heading, ErrorBox } from '../ui'

class Login extends Component {
  state = {
    loading: false,
    email: '',
    password: '',
    error: null,
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, error: null })
  }

  handleSubmit = () => {
    this.setState({ loading: true, error: null }, () => {
      api.post('/authenticate', { email: this.state.email, password: this.state.password }).then(
        ({ data }) => this.props.setAuth(data.user, data.token),
        (err) => this.setState({ error: err.response.data.error, loading: false })
      )
    })
  }

  componentDidMount() {
    this.emailRef.focus()
  }

  render() {
    let { loading, error, email, password } = this.state

    return (
      <Column height="100vh" justifyContent="center" alignItems="center">
        <Box width="100%" maxWidth={400} p={4} bg="white" borderRadius={3} boxShadow="md">
          <form onSubmit={(e) => e.preventDefault()}>
            <Column>
              <Row justifyContent="center">
                <Heading size="lg">Log In</Heading>
              </Row>

              {error && <ErrorBox>{error}</ErrorBox>}

              <Column mb={4}>
                <Box mb={1} fontSize="sm" fontWeight="bold">
                  Email
                </Box>
                <Box>
                  <Input
                    type="text"
                    size="sm"
                    name="email"
                    value={email}
                    placeholder="Email"
                    autoComplete="off"
                    ref={(r) => (this.emailRef = r)}
                    onChange={this.handleChange}
                  />
                </Box>
              </Column>

              <Column mb={4}>
                <Box mb={1} fontSize="sm" fontWeight="bold">
                  Password
                </Box>
                <Box>
                  <Input
                    type="password"
                    size="sm"
                    name="password"
                    value={password}
                    placeholder="Password"
                    autoComplete="off"
                    onChange={this.handleChange}
                  />
                </Box>
              </Column>

              <Row justifyContent="flex-end">
                <Button
                  size="sm"
                  variantColor="blue"
                  isLoading={loading}
                  onClick={this.handleSubmit}
                >
                  Log In
                </Button>
              </Row>
            </Column>
          </form>
        </Box>
      </Column>
    )
  }
}

export default Login
