import React from 'react'
import { navigate } from '@reach/router'
import { toast } from 'react-toastify'
import {
  Box,
  Row,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  ButtonLink,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Heading,
  Stack,
  Spacer,
} from '../ui'

const initialState = {
  loading: false,
  name: '',
  entities: [],
  capability_types: [],
  errors: [],
}

export default class Plans extends React.Component {
  state = initialState

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleCheckChange = (e, capability_type) => {
    let isChecked = e.target.checked

    this.setState((state) => ({
      ...state,
      capability_types: isChecked
        ? [...state.capability_types, capability_type.id]
        : state.capability_types.filter((c) => c !== capability_type.id),
    }))
  }

  handleAddEntity = (e) => {
    e.preventDefault()
    e.stopPropagation()

    this.setState((state) => ({ ...state, entities: [...state.entities, ''] }))
  }

  handleEntityChange = (value, index) => {
    this.setState((state) => ({
      ...state,
      entities: state.entities.map((ent, i) => (i === index ? value : ent)),
    }))
  }

  handleRemoveEntity = (index) => {
    this.setState((state) => ({
      ...state,
      entities: state.entities.filter((_, i) => i !== index),
    }))
  }

  validateForm = () => {
    let errors = []

    if (!this.state.name || this.state.name.length === 0) {
      errors.push('Plan title cannot be empty')
    }

    if (this.state.entities.filter((e) => e && e.length > 0).length === 0) {
      errors.push('Plan must have at least one entity')
    }

    if (this.state.capability_types.length === 0) {
      errors.push('Plan must have at least one capability type')
    }

    return errors
  }

  handleSubmit = (e) => {
    e.preventDefault()

    let errors = this.validateForm()

    if (errors.length > 0) {
      this.setState({ errors })
      return
    }

    this.setState({ loading: true }, () => {
      this.props
        .createPlan({
          name: this.state.name,
          capability_types: this.state.capability_types,
          entities: this.state.entities
            .filter((e) => e && e.length > 0)
            .map((entity, i) => ({ name: entity, primary: i === 0 })),
        })
        .then(
          (plan) => navigate(`/plans/${plan.id}`),
          (err) => {
            this.setState({ loading: false })
            toast.error('There was a problem')
          }
        )
    })
  }

  componentDidMount() {
    this.titleRef.focus()
  }

  render() {
    let { loading, errors, name, capability_types, entities } = this.state
    let { capability_types: existingCapabilityTypes } = this.props

    return (
      <>
        <Heading size="lg">New Plan</Heading>

        <Spacer spacing={4} />

        <Box bg="white" borderRadius={3} p={4}>
          {errors.length > 0 && (
            <>
              <Alert status="error">
                <AlertIcon />
                <AlertTitle mr={2}>Invalid Form</AlertTitle>
              </Alert>

              <Alert status="error" flexDirection="column" alignItems="flex-start">
                {errors.map((error, i) => (
                  <AlertDescription key={i} fontSize="sm">
                    {error}
                  </AlertDescription>
                ))}
              </Alert>

              <Spacer spacing={6} />
            </>
          )}

          <form onSubmit={this.handleSubmit}>
            <Stack isInline spacing={4}>
              {/* LEFT COLUMN */}
              <Stack flex={1} spacing={4}>
                <FormControl>
                  <FormLabel fontSize="sm">Title</FormLabel>
                  <Input
                    size="sm"
                    placeholder="Plan Title"
                    autoComplete="off"
                    name="name"
                    value={name}
                    onChange={this.handleChange}
                    ref={(r) => (this.titleRef = r)}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize="sm">Entities</FormLabel>

                  {entities.length === 0 && (
                    <Box height="32px" fontSize="sm">
                      <em>No entities added yet</em>
                    </Box>
                  )}

                  <Stack>
                    {entities.map((entity, i) => (
                      <Row key={i}>
                        <Input
                          size="sm"
                          placeholder="Entity Name"
                          autoComplete="off"
                          value={entity}
                          onChange={(e) => this.handleEntityChange(e.target.value, i)}
                        />

                        <Box px={2}>
                          <IconButton
                            size="sm"
                            variant="ghost"
                            icon="close"
                            onClick={() => this.handleRemoveEntity(i)}
                          />
                        </Box>
                      </Row>
                    ))}
                  </Stack>
                </FormControl>

                <Box>
                  <Button size="sm" onClick={this.handleAddEntity}>
                    + Add Entity
                  </Button>
                </Box>
              </Stack>

              {/* RIGHT COLUMN */}
              <Box flex={1}>
                <FormControl>
                  <FormLabel fontSize="sm">Capability Types</FormLabel>
                  <CheckboxGroup
                    variantColor="blue"
                    value={capability_types}
                    onChange={(value) => this.setState({ capability_types: value })}
                  >
                    {existingCapabilityTypes.map((type) => (
                      <Checkbox key={type.id} value={type.id} fontSize="sm">
                        {type.name}
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                </FormControl>
              </Box>
            </Stack>

            <Row justifyContent="flex-end">
              <ButtonLink size="sm" variant="ghost" to="/plans" isDisabled={loading} mr={2}>
                Cancel
              </ButtonLink>

              <Button type="submit" size="sm" variantColor="blue" isDisabled={loading}>
                Create Plan
              </Button>
            </Row>
          </form>
        </Box>
      </>
    )
  }
}
