import React from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Badge,
  Button,
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  Collapse,
  Divider,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Editable,
  EditableInput,
  EditablePreview,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
  MenuOptionGroup,
  MenuItemOption,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/core'
import Row from './row'
import Column from './column'
import ButtonLink from './button-link'
import NavLink from './nav-link'
import Dialog from './Dialog'
import InputRadio from './InputRadio'
import Select, { CreatableSelect } from './Select'
import Table, { useTable } from './table'

const Spacer = ({ spacing = 4 }) => <Box height={spacing * 0.25 + 'rem'} />

const ErrorBox = ({ children, p = 3, mb = 3 }) => (
  <Box bg="#e3b7bd" borderRadius={2} p={p} mb={mb}>
    {children}
  </Box>
)

export {
  Box,
  Row,
  Column,
  ButtonLink,
  Dialog,
  ErrorBox,
  InputRadio,
  NavLink,
  Select,
  CreatableSelect,
  // chakra-ui
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Badge,
  Button,
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  Collapse,
  Divider,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Editable,
  EditableInput,
  EditablePreview,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
  MenuOptionGroup,
  MenuItemOption,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Radio,
  RadioGroup,
  Spacer,
  Spinner,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Table,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useTable,
}
