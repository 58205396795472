import React from 'react'
import { Box, Row, ButtonLink, Spacer } from '../ui'
import PlanTable from './plan-table'

const Plans = ({ plans }) => (
  <>
    <Row justifyContent="flex-end">
      <Box>
        <ButtonLink to="/plans/new" size="sm" variantColor="blue">
          New Plan
        </ButtonLink>
      </Box>
    </Row>

    <Spacer spacing={4} />

    <PlanTable data={plans} />

    {plans.length === 0 && (
      <Box m={2} fontSize={13}>
        <em>No plans created yet</em>
      </Box>
    )}
  </>
)

export default Plans
