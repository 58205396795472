import EventEmitter from 'events'
import Cookies from 'js-cookie'
import { setAxiosDefaultHeader } from '../api'

class AuthEmitter extends EventEmitter {}

const auth = new AuthEmitter()

auth.on('clear-auth', () => {
  console.log('auth::clear-auth')

  setAxiosDefaultHeader('Authorization', null)
  Cookies.remove('token')
})

export default auth
