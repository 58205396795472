import React from 'react'
import { css } from '@emotion/core'
import { DateTime } from 'luxon'
import {
  Check as CheckIcon,
  CheckCircle as CheckCircleIcon,
  Trash as TrashIcon,
  Circle as CircleIcon,
  X as XIcon,
} from 'react-feather'
import {
  Box,
  Column,
  Row,
  Button,
  ButtonLink,
  Collapse,
  Editable,
  EditableInput,
  EditablePreview,
  Heading,
  Spacer,
  Stack,
  Text,
} from '../ui'
import { addEntityTypes, SATISFIED, sortCategories, sortByName } from '../utils'
import { DeletePlanDialog } from './dialogs'

const CapabilityRow = ({ capability, entity, index, mb }) => (
  <Row alignItems="flex-start" mb={mb}>
    <Column mr={2} justifyContent="flex-start">
      {capability.satisfied === SATISFIED.not_satisfied && <XIcon size={18} />}
      {capability.satisfied === SATISFIED.satisfied && <CheckIcon size={18} />}
      {capability.satisfied === SATISFIED.satisfied_other && <CheckCircleIcon size={18} />}
      {capability.satisfied === SATISFIED.other && <CircleIcon size={18} />}
    </Column>
    <Column>
      <Row>
        <Box>
          {index + 1}. {capability.name}
        </Box>
      </Row>
      <Box>
        <b
          css={css`
            font-weight: normal;
            color: #808a90;
          `}
        >
          {capability.narrative ? capability.narrative.replace(/\{entity\}/g, entity.name) : ''}
        </b>
      </Box>
    </Column>
  </Row>
)

export default class Plan extends React.Component {
  state = {
    deleteDialog: null,
  }

  startDelete = ({ id, name }) => this.setState({ deleteDialog: { id, name } })

  closeDeleteDialog = () => this.setState({ deleteDialog: null })

  doUpdate = (id, data) => this.props.updatePlan(id, data)

  doDelete = () => this.props.deletePlan(this.state.deleteDialog.id).then(this.closeDeleteDialog)

  render() {
    let { deleteDialog } = this.state
    let { id, plans = [], capability_types, generatePlanDoc } = this.props

    let plan = plans.find((p) => p.id === id)

    if (!plan) {
      return (
        <Row justifyContent="center">
          <Box fontSize="lg">Unknown plan</Box>
        </Row>
      )
    }

    let organized = addEntityTypes({ entities: plan.entities, capability_types })

    return (
      <Stack fontSize="sm" mb={6} spacing={4}>
        <Row alignItems="center">
          <Box flex={1}>
            <Editable
              defaultValue={plan.name}
              onSubmit={(name) => this.doUpdate(plan.id, { name })}
            >
              <EditablePreview fontSize="lg" />
              <EditableInput fontSize="lg" />
            </Editable>
          </Box>

          <Box>
            <Button size="sm" variant="outline" mr={2} onClick={() => this.startDelete(plan)}>
              <TrashIcon size={16} />
            </Button>

            <Button size="sm" variantColor="blue" onClick={() => generatePlanDoc(plan)}>
              Generate Doc
            </Button>
          </Box>
        </Row>

        <Row alignItems="center">
          <Box flex={1}>
            <IconLegend />
          </Box>

          <Row>
            <Timestamp label="Created" ts={plan.created_at} />
            <Timestamp label="Updated" ts={plan.updated_at} />
          </Row>
        </Row>

        <Stack spacing={4}>
          {organized.map((entity) => (
            <EntityPanel key={entity.id} entity={entity} plan={plan} />
          ))}
        </Stack>

        {deleteDialog && (
          <DeletePlanDialog
            acceptHandler={this.doDelete}
            cancelHandler={this.closeDeleteDialog}
            {...this.state.deleteDialog}
          />
        )}
      </Stack>
    )
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////

const EntityPanel = ({ entity, plan, mb }) => {
  let [isOpen, setIsOpen] = React.useState(false)

  return (
    <Box bg="white" p={4} borderRadius={3} boxShadow="md" mb={mb}>
      <Row alignItems="center" cursor="pointer" onClick={() => setIsOpen((state) => !state)}>
        <Box flex="1">
          <Heading size="sm" fontWeight={500}>
            {entity.name}
          </Heading>
        </Box>

        <Box>
          <ButtonLink size="sm" variantColor="blue" to={`/plans/${plan.id}/survey/${entity.id}`}>
            Enter Survey
          </ButtonLink>
        </Box>
      </Row>

      <Collapse isOpen={isOpen}>
        {sortByName(entity.types).map((capability_type) => (
          <React.Fragment key={`${entity.id}-${capability_type.id}`}>
            <Spacer spacing={4} />

            <Heading size="sm" fontWeight={400}>
              {capability_type.name}
            </Heading>

            <Spacer spacing={2} />

            {capability_type.grouped && (
              <Stack spacing={4}>
                {sortCategories(Object.keys(capability_type.grouped)).map((category) => (
                  <Column key={category} ml={3}>
                    <Text fontSize="sm" fontWeight={600}>
                      {category}
                    </Text>

                    <Spacer spacing={2} />

                    <Stack spacing={2}>
                      {sortByName(capability_type.grouped[category]).map((capability, index) => (
                        <CapabilityRow
                          key={capability.id}
                          capability={capability}
                          entity={entity}
                          index={index}
                        />
                      ))}
                    </Stack>
                  </Column>
                ))}
              </Stack>
            )}

            {capability_type.simple && (
              <Stack ml={3} spacing={2}>
                {sortByName(capability_type.simple).map((capability, index) => (
                  <CapabilityRow
                    key={capability.id}
                    capability={capability}
                    entity={entity}
                    index={index}
                  />
                ))}
              </Stack>
            )}
          </React.Fragment>
        ))}
      </Collapse>
    </Box>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////

const Timestamp = ({ label, ts = null }) => (
  <Column alignItems="center" mx={2}>
    <Box color="#808a90" fontSize={11}>
      {label}
    </Box>
    <Box color="#616d74">{ts ? DateTime.fromISO(ts).toLocaleString() : '-'}</Box>
  </Column>
)

const LegendItem = ({ icon, label }) => (
  <Row alignItems="center" mr={3}>
    {icon} &nbsp;&nbsp;{' '}
    <Box>
      <em>{label}</em>
    </Box>
  </Row>
)

const IconLegend = ({ mb }) => (
  <Row mb={mb} fontSize="xs">
    <LegendItem icon={<XIcon size={18} />} label="Not Satisfied" />
    <LegendItem icon={<CheckIcon size={18} />} label="Satisfied" />
    <LegendItem icon={<CheckCircleIcon size={18} />} label="Satisfied by Other Entity" />
    <LegendItem icon={<CircleIcon size={18} />} label="Other Value" />
  </Row>
)
