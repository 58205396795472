import React, { Component } from 'react'
import { CSSReset, ThemeProvider } from '@chakra-ui/core'
import { Router, Redirect, navigate } from '@reach/router'
import { ToastContainer } from 'react-toastify'
import { saveAs } from 'file-saver'
import Cookies from 'js-cookie'
import auth from './auth'
import Header from './header'
import Login from './auth/login'
import Plans from './plans'
import Plan from './plans/plan'
import PlanForm from './plans/plan-form'
import PlanSurvey from './plans/survey'
import Capabilities from './capabilities'
import { Box, Column, Row } from './ui'
import theme from './ui/theme'
import GlobalStyles from './style'
import api, { docApi, setAxiosDefaultHeader } from './api'

// import Users from './users-form'
// <Users users={users} createUser={this.createUser} />

const Empty = () => null
const NotFound = () => <div>404 - Not Found</div>

const AuthRoute = ({ component: Component, hasAuth = false, ...rest }) =>
  hasAuth ? <Component {...rest} /> : <Redirect to="/login" noThrow />

class App extends Component {
  state = {
    authUser: null,
    token: Cookies.get('token'),
    users: [],
    plans: [],
    capability_types: [],
    capabilities: [],
  }

  setAuth = (authUser, token) => {
    Cookies.set('token', token, { path: '/' })

    setAxiosDefaultHeader('Authorization', `Bearer ${token}`)

    this.loadInitialData().then(({ data }) => {
      let { users, plans, capability_types, capabilities } = data

      this.setState({ token, authUser, users, plans, capability_types, capabilities }, () =>
        navigate('/')
      )
    })
  }

  loadInitialData = () => api.get('/')

  // users

  createUser = (data) => {
    api.post('/users', data).then(({ data }) => {
      console.log('new user data', data)

      this.setState((state) => ({ ...state, users: [...state.users, data.user] }))
    })
  }

  // plans

  createPlan = (data) => {
    console.log('createPlan', data)

    return api.post('/plans', data).then(({ data }) => {
      this.setState((state) => ({ ...state, plans: [...state.plans, data.plan] }))

      return data.plan
    })
  }

  updatePlan = (id, data) => {
    console.log('updatePlan', data)

    return api.put(`/plans/${id}`, data).then(({ data }) => {
      this.setState((state) => ({
        ...state,
        plans: state.plans.map((p) => (p.id === data.plan.id ? data.plan : p)),
      }))

      return data.plan
    })
  }

  deletePlan = (id) => {
    return api.delete(`/plans/${id}`).then(() => {
      navigate('/plans')

      this.setState((state) => ({
        ...state,
        plans: state.plans.filter((p) => p.id !== id),
      }))
    })
  }

  generatePlanDoc = (plan) => {
    console.log('generatePlanDoc', plan)

    api.get(`/plans/${plan.id}/document-data`).then(({ data }) => {
      docApi
        .request({
          responseType: 'arraybuffer',
          url: '/run',
          method: 'post',
          data,
        })
        .then(
          ({ data }) => {
            saveAs(
              new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              }),
              `plan-${plan.id}-${new Date().toISOString()}.docx`
            )
          },
          (err) => console.error(JSON.stringify(err))
        )
    })
  }

  // capability types

  createCapabilityType = (data) => {
    console.log('createCapabilityType', data)

    return api.post('/capability-types', data).then(({ data }) => {
      console.log('new capability_type data', data)

      this.setState((state) => ({
        ...state,
        capability_types: [...state.capability_types, data.capability_type],
      }))
    })
  }

  updateCapabilityType = (id, data) => {
    console.log('updateCapabilityType', data)

    return api.put(`/capability-types/${id}`, data).then(({ data }) => {
      let capabilityType = data.capability_type

      this.setState((state) => ({
        ...state,
        capability_types: state.capability_types.map((t) => (t.id === id ? capabilityType : t)),
      }))
    })
  }

  // template capabilities

  createCapability = (data) => {
    console.log('createCapability', data)

    return api.post('/capabilities', data).then(({ data }) => {
      console.log('new capability data', data)

      this.setState((state) => ({
        ...state,
        capabilities: [...state.capabilities, data.capability],
      }))
    })
  }

  saveCapability = ({ id, data }) => {
    console.log('saveCapability', { id, data })

    if (id) return this._updateCapability(id, data)

    return this._createCapability(data)
  }

  _updateCapability = (id, data) => {
    return api.put(`/capabilities/${id}`, data).then(({ data }) => {
      let capability = data.capability

      this.setState((state) => ({
        ...state,
        capabilities: state.capabilities.map((c) => (c.id === capability.id ? capability : c)),
      }))

      return capability
    })
  }

  _createCapability = (data) => {
    return api.post(`/capabilities`, data).then(({ data }) => {
      let capability = data.capability

      this.setState((state) => ({
        ...state,
        capabilities: [...state.capabilities, capability],
      }))

      return capability
    })
  }

  deleteCapability = (id) => {
    return api.delete(`/capabilities/${id}`).then(() => {
      this.setState((state) => ({
        ...state,
        capabilities: state.capabilities.filter((c) => c.id !== id),
      }))
    })
  }

  // plan capabilities

  updatePlanCapability = ({ id, data }) => {
    return new Promise((yah, nah) => {
      api.put(`/plan-capability/${id}`, data).then(({ data }) => {
        let capability = data.capability

        this.setState(
          (state) => ({
            ...state,
            plans: state.plans.map((plan) =>
              plan.id === capability.plan_id
                ? {
                    ...plan,
                    entities: plan.entities.map((entity) =>
                      entity.id === capability.entity_id
                        ? {
                            ...entity,
                            capabilities: entity.capabilities.map((cap) =>
                              cap.id === id ? capability : cap
                            ),
                          }
                        : entity
                    ),
                  }
                : plan
            ),
          }),
          () => yah(capability)
        )
      })
    })
  }

  componentDidMount() {
    auth.on('clear-auth', () => {
      console.log('app::clear-auth, updating state, navigating')

      this.setState({ token: null }, () => navigate('/login'))
    })

    if (this.state.token) {
      this.loadInitialData().then(({ data }) => {
        let { user, users, plans, capability_types, capabilities } = data

        this.setState({ authUser: user, users, plans, capability_types, capabilities })
      })
    }
  }

  render() {
    let { /* users, */ token, authUser, plans, capability_types, capabilities } = this.state

    let hasAuth = !!authUser && !!token

    // console.log('APP - render', { hasAuth, authUser, token })

    // need to try fetching initial data
    if (token && !authUser) return null

    return (
      <ThemeProvider theme={theme}>
        <CSSReset />
        <GlobalStyles />

        <Column minHeight="100vh" color="#152935" bg="#f8f9f9">
          <ToastContainer position="top-right" autoClose={false} newestOnTop={false} closeOnClick />

          <Router primary={false}>
            <Empty path="/login" />
            <Header default authUser={authUser} />
          </Router>

          <Row justifyContent="center" alignItems="flex-start" minHeight="100vh">
            <Box width="85%" pt={8}>
              <Router primary={false}>
                <Login path="/login" setAuth={this.setAuth} />

                <AuthRoute
                  path="/"
                  component={() => <Redirect to="/plans" noThrow />}
                  hasAuth={hasAuth}
                />

                <AuthRoute
                  path="/plans"
                  hasAuth={hasAuth}
                  component={Plans}
                  plans={plans}
                  capability_types={capability_types}
                  createPlan={this.createPlan}
                />

                <AuthRoute
                  path="/plans/new"
                  hasAuth={hasAuth}
                  component={PlanForm}
                  plans={plans}
                  capability_types={capability_types}
                  createPlan={this.createPlan}
                />

                <AuthRoute
                  path="/plans/:id"
                  hasAuth={hasAuth}
                  component={Plan}
                  plans={plans}
                  capability_types={capability_types}
                  generatePlanDoc={this.generatePlanDoc}
                  updatePlan={this.updatePlan}
                  deletePlan={this.deletePlan}
                />

                <AuthRoute
                  path="/plans/:plan_id/survey/:entity_id"
                  hasAuth={hasAuth}
                  component={PlanSurvey}
                  plans={plans}
                  capability_types={capability_types}
                  updatePlanCapability={this.updatePlanCapability}
                />

                <AuthRoute
                  path="/capability-templates"
                  hasAuth={hasAuth}
                  component={Capabilities}
                  capability_types={capability_types}
                  capabilities={capabilities}
                  updateCapabilityType={this.updateCapabilityType}
                  saveCapability={this.saveCapability}
                  deleteCapability={this.deleteCapability}
                />

                <NotFound default />
              </Router>
            </Box>
          </Row>
        </Column>
      </ThemeProvider>
    )
  }
}

export default App
