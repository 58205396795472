import React from 'react'
import {
  Check as CheckIcon,
  CheckCircle as CheckCircleIcon,
  Circle as CircleIcon,
  X as XIcon,
} from 'react-feather'
import { capitalize } from 'lodash'
import { Box, Row, Column, Button, Collapse, IconButton, Input, Spacer } from '../ui'
import { apiErrorToast } from '../utils'

const NARRATIVE_KEYS = {
  satisfied: {
    icon: <CheckIcon size={18} />,
  },
  not_satisfied: {
    icon: <XIcon size={18} />,
  },
  satisfied_other: {
    icon: <CheckCircleIcon size={18} />,
  },
  other: {
    icon: <CircleIcon size={18} />,
  },
}

class CapabilityTemplate extends React.Component {
  state = {
    capability: this.props.capability,
    isOpen: this.props.isEditing || false,
    isSaving: false,
  }

  handleChange = (e) => {
    e.stopPropagation()

    let target = e.currentTarget

    this.setState((state) => ({
      ...state,
      capability: {
        ...state.capability,
        [target.name]: target.value,
      },
    }))
  }

  handleNarrativeChange = (key) => (e) => {
    let target = e.currentTarget

    this.setState((state) => ({
      ...state,
      capability: {
        ...state.capability,
        narratives: {
          ...state.capability.narratives,
          [key]: target.value,
        },
      },
    }))
  }

  handleCancelSave = () => {
    this.setState({ capability: this.props.capability })
    this.props.updateEditingId(null)
  }

  handleSave = () => {
    let { capability } = this.state
    let { id, name, narratives } = capability

    this.setState({ isSaving: true })

    this.props
      .saveCapability({ id, data: { name, narratives } })
      .then(this.props.afterSave || this.afterSave)
      .catch(apiErrorToast)
  }

  afterSave = (capability) => {
    if (!capability) return

    this.setState({ capability })
    this.props.updateEditingId(null)
  }

  handleDelete = (e) => {
    e.stopPropagation()

    let { id, name } = this.state.capability

    this.props.startDelete({ id, name })
  }

  render() {
    let { capability, isOpen, isSaving } = this.state
    let { index, isEditing, otherIsEditing, mb } = this.props

    const toggleOpen = () =>
      !isEditing ? this.setState((state) => ({ ...state, isOpen: !state.isOpen })) : undefined

    return (
      <Row
        alignItems="flex-start"
        px={4}
        py={2}
        borderRadius={3}
        _hover={{ boxShadow: 'sm' }}
        boxShadow={isOpen ? 'sm' : undefined}
        css={{
          opacity: otherIsEditing ? 0.5 : 1,

          '& .hover-show': !isOpen && {
            opacity: 0,
            transition: 'opacity 200ms linear',
          },

          '&:hover .hover-show': {
            opacity: 1,
          },
        }}
        mb={mb}
      >
        <Column flex="1" fontSize="sm">
          <Row
            width="100%"
            minHeight="2rem"
            mb={isOpen ? 2 : 0}
            onClick={toggleOpen}
            cursor="pointer"
          >
            <Row width="100%" flex={1} alignItems="center">
              {!isEditing && (
                <Box fontSize={16} fontWeight="500">
                  {index + 1}. {capability.name}
                </Box>
              )}
              {isEditing && (
                <Box flex={1}>
                  <Input
                    type="text"
                    size="sm"
                    variant="flushed"
                    name="name"
                    value={capability.name || ''}
                    placeholder="Name"
                    autoComplete="off"
                    onChange={this.handleChange}
                  />
                </Box>
              )}
            </Row>

            {!isEditing && !otherIsEditing && (
              <Row justifyContent="space-between" className="actions">
                <IconButton
                  className="hover-show"
                  size="sm"
                  variant="ghost"
                  aria-label="Delete Capability"
                  icon="delete"
                  isDisabled={!capability.id}
                  mr={2}
                  onClick={this.handleDelete}
                />

                <IconButton
                  className="hover-show"
                  size="sm"
                  variant="ghost"
                  aria-label="Edit Capability"
                  icon="edit"
                  isDisabled={!capability.id}
                  mr={2}
                  onClick={(e) => {
                    e.stopPropagation()
                    this.setState({ isOpen: true })
                    this.props.updateEditingId(capability.id)
                  }}
                />

                <IconButton
                  size="sm"
                  variant="ghost"
                  color="gray.500"
                  aria-label="Expand/Collapse"
                  icon={isOpen ? 'triangle-up' : 'triangle-down'}
                />
              </Row>
            )}
          </Row>

          <Collapse isOpen={isOpen}>
            {Object.keys(NARRATIVE_KEYS).map((key) => {
              return (
                <Row key={key} alignItems="center" minHeight="2rem">
                  <Row width={140} alignItems="center" pr={2} color="#777">
                    {NARRATIVE_KEYS[key].icon}&nbsp;&nbsp;
                    {key.split('_').map(capitalize).join(' ')}
                  </Row>

                  <Box flex={1}>
                    {!isEditing && (
                      <>
                        {capability.narratives && capability.narratives[key] ? (
                          capability.narratives[key]
                        ) : (
                          <em>Not Specified</em>
                        )}
                      </>
                    )}

                    {isEditing && (
                      <Input
                        type="text"
                        size="sm"
                        variant="flushed"
                        value={capability.narratives[key] || ''}
                        placeholder={`Narrative: ${key}`}
                        autoComplete="off"
                        onChange={this.handleNarrativeChange(key)}
                      />
                    )}
                  </Box>
                </Row>
              )
            })}

            {isEditing && (
              <>
                <Spacer spacing={4} />

                <Row width="100%" justifyContent="flex-end">
                  <Button
                    size="sm"
                    variant="ghost"
                    mr={2}
                    isDisabled={isSaving}
                    onClick={this.props.handleCancelSave || this.handleCancelSave}
                  >
                    Cancel
                  </Button>

                  <Button
                    width={60}
                    size="sm"
                    variantColor="blue"
                    isLoading={isSaving}
                    onClick={this.handleSave}
                  >
                    Save
                  </Button>
                </Row>
              </>
            )}
          </Collapse>
        </Column>
      </Row>
    )
  }
}

export default CapabilityTemplate
