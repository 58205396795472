import React from 'react'
import { Box, Column, Button, Dialog } from '../ui'

export class DeletePlanDialog extends React.Component {
  dialogRef = React.createRef()

  render() {
    return (
      <Dialog isOpen={true} initialFocusRef={this.dialogRef} onDismiss={this.props.cancelHandler}>
        <Dialog.Title>Delete Plan</Dialog.Title>

        <Dialog.Body>
          <Column>
            <Box>Are you sure you want to delete this plan?</Box>
            <ul>
              <li>{this.props.name}</li>
            </ul>
          </Column>
        </Dialog.Body>

        <Dialog.Footer>
          <Button outline mr={2} ref={this.dialogRef} onClick={this.props.cancelHandler}>
            Cancel
          </Button>

          <Button color="primary" onClick={this.props.acceptHandler}>
            Delete
          </Button>
        </Dialog.Footer>
      </Dialog>
    )
  }
}
