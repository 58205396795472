import React from 'react'
import { css } from '@emotion/core'
import isPropValid from '@emotion/is-prop-valid'
import { Link } from '@reach/router'
import styled from '@emotion/styled'
import { width, space } from 'styled-system'

const NavLinkItem = styled(Link, {
  // don't forward props to the dom element
  shouldForwardProp: (prop) => isPropValid(prop),
})(
  ({ active }) =>
    css`
      display: block;
      padding: 4px 8px;
      margin: 0 8px;
      border-bottom: ${active ? `2px solid #2a69ac` : '2px solid transparent'};
      text-align: center;
      transition: border-bottom 0.3s;

      &:hover,
      &:focus {
        text-decoration: none;
        border-bottom: 2px solid #2a69ac;
      }
    `,
  width,
  space
)

const NavLink = ({ to, active = false, children }) => {
  return (
    <NavLinkItem to={to} active={active}>
      {children}
    </NavLinkItem>
  )
}

export default NavLink
