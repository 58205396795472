import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import isEqual from 'react-fast-compare'
import omit from 'lodash/omit'
import {
  Button,
  Box,
  Row,
  Column,
  CreatableSelect,
  Checkbox,
  InputRadio,
  Input,
  FormControl,
  FormLabel,
  Spacer,
  Textarea,
} from '../../ui'
import { SATISFIED } from '../../utils'

const CapabilityBox = styled(Column)(
  ({ isActive }) => css`
    display: flex;
    border: ${isActive ? '1px solid #5397bc' : '1px solid #eee'};
    border-radius: 3px;
    box-shadow: 0px 1px 4px 0px #e0e0e0;
    opacity: ${isActive ? 1 : 0.66};
    cursor: ${isActive ? 'inherit' : 'pointer'};

    & > * {
      pointer-events: ${isActive ? 'auto' : 'none'};
    }
  `
)

class CapabilityRow extends React.Component {
  static cref = null

  state = {
    isSaving: false,
    values: this.props.values,
  }

  updateTouched = () => {
    if (this.props.isTouched && isEqual(this.state.values, this.props.values)) {
      this.props.setParentState({ touchedId: null })
    } else if (!this.props.isTouched) {
      this.props.setParentState({ touchedId: this.props.capability.id })
    }
  }

  createHandleRadioChange = (value) => (e) => {
    let { capability } = this.props

    this.setState(
      (state) => ({
        ...state,
        values: {
          ...state.values,
          satisfied: value,
          // update narrative if a narrative template exists
          narrative: capability.narratives[value]
            ? capability.narratives[value]
            : state.values.narrative,
          // null out related fields when switching
          other: value === SATISFIED.other ? state.values.other : null,
          satisfied_other_entity:
            value === SATISFIED.satisfied_other ? state.values.satisfied_other_entity : null,
        },
      }),
      this.updateTouched
    )
  }

  handleClickSave = async () => {
    this.setState({ isSaving: true })

    try {
      await this.props.saveCapability({ id: this.props.capability.id, data: this.state.values })
    } catch (err) {}

    this.setState({ isSaving: false })
  }

  handleClickCancel = () => {
    this.setState({ values: this.props.values }, () => {
      this.props.setParentState({ touchedId: null, promptSaveId: null })
    })
  }

  componentDidMount() {
    this.props.updateCapRefs({ [this.props.capability.id]: this.cref })
  }

  shouldComponentUpdate(nextProps, nextState) {
    let omitProps = [
      // data
      'entities', // could need to care if an entity name changes?
      'entity',
      // fns
      'saveCapability',
      'setActiveId',
      'setParentState',
      'updateCapRefs',
    ]

    // if both comparisons are true, return false (don't update)
    return !(
      isEqual(omit(this.props, ...omitProps), omit(nextProps, ...omitProps)) &&
      isEqual(this.state, nextState)
    )
  }

  render() {
    let { isSaving, values } = this.state
    let {
      index,
      capability,
      entities,
      entity,
      isTouched,
      isActive,
      isPromptedSave,
      mb,
    } = this.props

    let entityOptions = entities
      .filter(({ id }) => id !== entity.id)
      .map((entity) => ({ value: entity.name, label: entity.name }))

    let selectedOtherEntity = entityOptions.find((e) => e.value === values.satisfied_other_entity)

    if (values.satisfied_other_entity && !selectedOtherEntity) {
      selectedOtherEntity = {
        value: values.satisfied_other_entity,
        label: values.satisfied_other_entity,
      }

      entityOptions = [...entityOptions, selectedOtherEntity]
    }

    return (
      <CapabilityBox
        bg="white"
        p={4}
        mb={mb}
        isActive={this.props.isActive}
        ref={(r) => (this.cref = r)}
        onClick={() => this.props.setActiveId(capability.id)}
      >
        <Box fontSize={18}>
          {index + 1}. {capability.name}
        </Box>

        <Spacer spacing={4} />

        <Row ml={3} alignItems="flex-start">
          {/* LEFT COLUMN */}
          <Column width={280}>
            <Box my={1}>
              <InputRadio
                label="Not Satisfied"
                checked={values.satisfied === SATISFIED.not_satisfied}
                onChange={this.createHandleRadioChange(SATISFIED.not_satisfied)}
              />
            </Box>

            <Box my={1}>
              <InputRadio
                label="Satisfied"
                checked={values.satisfied === SATISFIED.satisfied}
                onChange={this.createHandleRadioChange(SATISFIED.satisfied)}
              />
            </Box>

            <Column>
              <InputRadio
                label="Satisfied by Other Entity"
                checked={values.satisfied === SATISFIED.satisfied_other}
                onChange={this.createHandleRadioChange(SATISFIED.satisfied_other)}
              />

              {values.satisfied === SATISFIED.satisfied_other && (
                <Box ml="22px" maxWidth={200}>
                  <CreatableSelect
                    options={entityOptions}
                    value={entityOptions.find((e) => e.value === values.satisfied_other_entity)}
                    onChange={(option) => {
                      this.setState(
                        (state) => ({
                          ...state,
                          values: {
                            ...state.values,
                            satisfied_other_entity: option ? option.value : null,
                          },
                        }),
                        this.updateTouched
                      )
                    }}
                    onCreateOption={(option) => {
                      this.setState(
                        (state) => ({
                          ...state,
                          values: {
                            ...state.values,
                            satisfied_other_entity: option ? option : null,
                          },
                        }),
                        this.updateTouched
                      )
                    }}
                  />
                </Box>
              )}
            </Column>

            <Column my={1}>
              <InputRadio
                label="Other Value"
                checked={values.satisfied === SATISFIED.other}
                onChange={this.createHandleRadioChange(SATISFIED.other)}
              />

              {values.satisfied === SATISFIED.other && (
                <Box ml="22px">
                  <Input
                    type="text"
                    size="sm"
                    width={200}
                    value={values.other || ''}
                    placeholder="Other Value"
                    onChange={(e) => {
                      let target = e.currentTarget

                      this.setState(
                        (state) => ({
                          ...state,
                          values: {
                            ...state.values,
                            other: target.value || '',
                          },
                        }),
                        this.updateTouched
                      )
                    }}
                  />
                </Box>
              )}
            </Column>
          </Column>

          {/* RIGHT COLUMN */}
          <Column flex={1}>
            <FormControl>
              <FormLabel fontSize="sm">Narrative</FormLabel>
              <Textarea
                fontSize="sm"
                placeholder="Additional Narrative Notes"
                value={(values.narrative || '').replace(/\{entity\}/g, entity.name)}
                onChange={(e) => {
                  let target = e.currentTarget

                  this.setState(
                    (state) => ({
                      ...state,
                      values: {
                        ...state.values,
                        narrative: target.value || '',
                      },
                    }),
                    this.updateTouched
                  )
                }}
              />
            </FormControl>

            <Spacer spacing={4} />

            <Box>
              <Checkbox
                size="sm"
                isChecked={!!values.project_candidate}
                onChange={(e) => {
                  let target = e.currentTarget

                  this.setState(
                    (state) => ({
                      ...state,
                      values: {
                        ...state.values,
                        project_candidate: target.checked,
                      },
                    }),
                    this.updateTouched
                  )
                }}
              >
                Mitigation Project Candidate
              </Checkbox>
            </Box>

            <Row width="100%">
              <Box flex={1} color="red">
                {isPromptedSave ? 'Please save or dismiss these changes.' : ''}
              </Box>
              <Row justifyContent="flex-end">
                {isTouched && (
                  <Button
                    variant="ghost"
                    mr={2}
                    isDisabled={!isTouched && !isActive}
                    onClick={this.handleClickCancel}
                  >
                    Dismiss
                  </Button>
                )}
                <Button
                  size="md"
                  variantColor="blue"
                  isDisabled={!isTouched && !isActive}
                  isLoading={isSaving}
                  onClick={this.handleClickSave}
                >
                  Save
                </Button>
              </Row>
            </Row>
          </Column>

          {false && (
            <Box>
              <pre>{JSON.stringify(values, null, 2)}</pre>
            </Box>
          )}
        </Row>
      </CapabilityBox>
    )
  }
}

export default CapabilityRow
